<template>
  <div class="detail_wrap">
    <div class="mask_img">
      <div class="title_img">
        <img src="../../assets/image/works/title.png" alt="" />
      </div>
      <div class="title">优秀作品</div>
    </div>
    <div class="detail_bg" v-if="false"></div>

    <div class="detail_content">
      <div
        class="movie_item"
        v-for="item in workList"
        :key="item.id"
        @click="playItem(item)"
      >
        <div class="item_bg">
          <div class="movie_wrap">
            <video
              :src="item.pictureUrl"
              style="width: 100%; height: 100%"
              :poster="item.coverUrl"
            ></video>
          </div>
          <div class="movie_play">
            <img src="../../assets/image/works/play.png" alt="" />
          </div>
        </div>
        <div class="movie_name">《{{ item.authorName }}》</div>
        <div class="movie_line">
          <div class="movie_desc" v-html="item.personalIntro"></div>
        </div>
      </div>
      <bottom-point class="bottom_wrap"></bottom-point>
      <div class="movie_star">
        <img src="../../assets/image/works/detail/movie_star.png" alt="" />
      </div>
    </div>

    <div class="playAlert" v-show="showAlert" @click="closeAlert">
      <div class="video_play" @click.stop="doNothing">
        <div class="movie_wrap">
          <video
            ref="myVideo"
            id="myVideo"
            :src="currentPlayItem.pictureUrl"
            :controls="true"
            autoplay
            :key="currentPlayItem.id"
            style="width: 100%"
          ></video>
        </div>
        <div class="movie_play" @click.stop="playOrPauss">
          <img v-if="!isPlay" src="../../assets/image/works/play.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomPoint from "@/components/BottomPoint";
import { queryForWorksList } from "@/apis/index";
export default {
  name: "aboutDetail",
  components: { bottomPoint },
  data() {
    return {
      isPlay: false,
      showAlert: false,
      workList: [],
      posterList: [],
      currentPlayItem: {},
    };
  },
  mounted() {
    this.addListener();
    this.checkList();
  },
  methods: {
    checkList() {
      queryForWorksList().then((res) => {
        console.log(res);
        if (res.code === 200) {
          var array = res.data;
          this.workList = array;
        }
      });
    },
    playItem(item) {
      this.currentPlayItem = item;
      this.showAlert = true;
      this.isPlay = true;
      this.stopMove();
    },
    closeAlert() {
      this.showAlert = false;
      this.$refs.myVideo.pause();
      this.isPlay = false;
      this.Move();
    },
    playOrPauss() {
      if (this.isPlay) {
        this.$refs.myVideo.pause();
        this.isPlay = false;
      } else {
        this.$refs.myVideo.play();
        this.isPlay = true;
      }
    },
    doNothing() {},
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
    addListener() {
      var elevideo = document.getElementById("myVideo");
      var that = this;
      elevideo.addEventListener("play", function () {
        //播放
        console.log("开始播放");
        that.isPlay = true;
      });
      elevideo.addEventListener("pause", function () {
        //暂停
        console.log("暂停");
        that.isPlay = false;
      });
      elevideo.addEventListener("ended", function () {
        //结束
        console.log("播放结束");
        that.isPlay = false;
      });
    },
    //截取视频第一帧作为播放前默认图片
    findvideocover(url) {
      //const video = document.getElementById("upvideo"); // 获取视频对象
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = url; // url地址 url跟 视频流是一样的
      var canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = 512; // 获取视频宽度
        canvas.height = 288; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, 512, 288);
        // 转换成base64形式
        return canvas.toDataURL("image/png"); // 截取后的视频封面
      };
    },
    getVideoBase64(url) {
      let dataURL = "";
      let video = document.createElement("video");
      video.setAttribute("crossOrigin", "anonymous"); //处理跨域
      video.setAttribute("src", url);
      video.setAttribute("width", 512);
      video.setAttribute("height", 288);
      video.currentTime = 5;
      video.addEventListener("loadeddata", () => {
        let canvas = document.createElement("canvas");
        const width = video.width; //canvas的尺寸和图片一样
        const height = video.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
        dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
        console.log(dataURL);
        // 使用箭头函数就没必要使用this.$refs
        // this.$refs.myimg.src = dataURL;
        // this.$refs.myvideo.poster = dataURL;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.playAlert {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  .video_play {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 270px;
    width: 1109px;
    height: 661px;
    background: url("../../assets/image/works/detail/movie_bg.png") center
      center no-repeat;
    background-size: 100% 100%;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;

    .movie_wrap {
      width: 1024px;
      height: 576px;
      // border-radius: 8px;
      // background-color: rebeccapurple;
    }

    .movie_play {
      position: absolute;
      width: 101px;
      height: 101px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.detail_wrap {
  position: relative;
  height: auto;
}
.detail_bg {
  position: fixed;
  width: 1770px;
  height: 927px;
  left: 50%;
  top: 140px;
  transform: translateX(-50%);
  background: url("../../assets/image/works/detail/detail_bg.png") center center
    no-repeat;
  background-size: 100% 100%;
  background-color: #12151a;
  z-index: 99;
}

.mask_img {
  position: fixed;
  width: 1920px;
  height: 416px;
  top: 0px;
  z-index: 120;
  background: url("../../assets/image/works/detail/mask_img.png") center center
    no-repeat;
  background-size: 100% 100%;

  .title_img {
    width: 1453px;
    height: 64px;
    margin-left: 111px;
    margin-top: 180px;
  }

  .title {
    padding-left: 111px;
    padding-top: 37px;
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
  }
}

.detail_content {
  position: relative;
  padding-top: 280px;
  z-index: 110;
  width: 1760px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .movie_item {
    width: 560px;
    height: auto;
    margin-right: 40px;
    margin-bottom: 83px;
    // background-color: rebeccapurple;

    &:nth-child(3n) {
      margin-right: 0px;
    }

    .item_bg {
      width: 100%;
      height: 331px;
      background: url("../../assets/image/works/detail/movie_bg.png") center
        center no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .movie_wrap {
        width: 512px;
        height: 288px;
        border-radius: 8px;
        // background-color: rebeccapurple;
      }

      .movie_play {
        position: absolute;
        width: 101px;
        height: 101px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .movie_name {
      font-size: 35px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 35px;
      padding-left: 21px;
      padding-top: 24px;
    }

    .movie_line {
      width: 100%;
      height: 67px;
      background: url("../../assets/image/works/detail/movie_line.png") center
        center no-repeat;
      background-size: 100% 100%;
      position: relative;

      .movie_desc {
        position: absolute;
        left: 47px;
        top: 49px;
        right: 100px;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 27px;
      }
    }
  }

  .movie_star {
    position: absolute;
    width: 372px;
    height: 265px;
    right: 0;
    bottom: 217px;
  }
}

.bottom_wrap {
  width: 1760px;
  margin-top: 80px;
  margin-bottom: -20px;
}
</style>